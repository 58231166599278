import React from 'react';
import { FaTimes } from 'react-icons/fa';
import '../../styles/App.css'; // Import the CSS for the modal

const Modal = ({ children, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button onClick={onClose} className="modal-close-button"><FaTimes /></button>
        {children}
      </div>
    </div>
  );
};

export default Modal;
