import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAuth } from '../services/AuthContext';
import '../styles/App.css';

const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();
  const { login } = useAuth();

  const onSubmit = async (data) => {
    try {
      await login(data.username, data.password);
      navigate('/');
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  return (
    <div className="login-container">
      <div className="logo-banner">
        <div className="text-logo">
          <span className="logo-part1">momentum</span>
          <span className="logo-part2">Peak</span>
        </div>
        <h1 className="login-title">Management Console</h1>
      </div>
      <div className="login-card">
        <h2>Login</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-group">
            <input
              name="username"
              {...register('username', { required: true })}
              type="text"
              placeholder="Username"
              className={errors.username ? 'input-error' : ''}
            />
            {errors.username && <p className="error-text">Username is required.</p>}
          </div>
          <div className="form-group">
            <input
              name="password"
              {...register('password', { required: true })}
              type="password"
              placeholder="Password"
              className={errors.password ? 'input-error' : ''}
            />
            {errors.password && <p className="error-text">Password is required.</p>}
          </div>
          <button type="submit" className="login-button">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
