import React, { createContext, useContext, useState } from 'react';
import { getToken } from './apiService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const login = async (username, password) => {
    try {
      const token = await getToken(username, password);
      setAuthToken(token);
      setUser({ username });
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const logout = () => {
    setAuthToken(null);
    setUser(null);
  };

  const getAuthToken = () => authToken;

  return (
    <AuthContext.Provider value={{ user, authToken, login, logout, getAuthToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
