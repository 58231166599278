import React from 'react';
import '../styles/App.css';

const Footer = () => (
  <footer className="dashboard-footer">
    <p>Mazisparrow LLC &copy; {new Date().getFullYear()} Momentum Peak. All rights reserved.</p>
  </footer>
);

export default Footer;
