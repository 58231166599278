import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom'; // Add this line
import { useTable, useSortBy, useFilters } from 'react-table';
import { fetchDevotions, createDevotion, updateDevotion, deleteDevotion } from '../services/apiService';
import { useAuth } from '../services/AuthContext';
import { FaEdit, FaTrash, FaPlus, FaFilter, FaUndo, FaSave } from 'react-icons/fa';
import Modal from './common/Modal'; // Import your modal component
import Header from './Header'; // Import the new Header component
import Footer from './Footer'; // Import the new Footer component
import '../styles/App.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const Devotion = () => {
  const [devotions, setDevotions] = useState([]);
  const [newDevotion, setNewDevotion] = useState({
    scriptures: [''],
    prayer: '',
    date: new Date(),
    devotion_id: ''
  });
  const [filterDate, setFilterDate] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteDevotionId, setDeleteDevotionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Use the hook here
  const { getAuthToken, logout } = useAuth();

  useEffect(() => {
    fetchAllDevotions();
  }, [getAuthToken]);

  const fetchAllDevotions = async () => {
    try {
      setIsLoading(true);
      const token = getAuthToken();
      const response = await fetchDevotions(token);
      if (response && response.is_success && Array.isArray(response.data)) {
        const devotionsWithDates = response.data.map(devotion => ({
          ...devotion,
          date: new Date(devotion.date),
        }));
        setDevotions(devotionsWithDates);
      } else {
        console.error("Expected an array but got:", response);
        setDevotions([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching devotions:', error);
      setDevotions([]);
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'scriptures') {
      setNewDevotion({ ...newDevotion, scriptures: [value] });
    } else {
      setNewDevotion({ ...newDevotion, [name]: value });
    }
  };

  const handleDateChange = (date) => {
    setNewDevotion({ ...newDevotion, date });
  };

  const handleFilterDateChange = (date) => {
    setFilterDate(date);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getAuthToken();
      if (isEditing) {
        const updatePayload = {
          prayer: newDevotion.prayer,
          date: newDevotion.date.toISOString().split('T')[0],
          scriptures: newDevotion.scriptures,
          devotion_id: newDevotion.devotion_id
        };
        await updateDevotion(token, updatePayload);
      } else {
        const createPayload = {
          ...newDevotion,
          date: newDevotion.date.toISOString().split('T')[0]
        };
        await createDevotion(token, createPayload);
      }
      setNewDevotion({ scriptures: [''], prayer: '', date: new Date(), devotion_id: '' });
      setShowModal(false);
      setIsEditing(false);
      fetchAllDevotions();
    } catch (error) {
      console.error('Error saving devotion:', error);
    }
  };

  const handleDelete = async () => {
    try {
      const token = getAuthToken();
      await deleteDevotion(token, deleteDevotionId);
      setShowDeleteModal(false);
      setDeleteDevotionId(null);
      fetchAllDevotions();
    } catch (error) {
      console.error('Error deleting devotion:', error);
    }
  };

  const handleFilterByDate = async () => {
    try {
      const token = getAuthToken();
      const response = await fetchDevotions(token);
      const filteredData = response.data.filter(devotion => {
        const devotionDate = new Date(devotion.date);
        return filterDate && devotionDate.toISOString().split('T')[0] === filterDate.toISOString().split('T')[0];
      });
      if (Array.isArray(filteredData)) {
        setDevotions(filteredData);
      } else {
        setDevotions([]);
      }
    } catch (error) {
      console.error('Error fetching devotions by date:', error);
    }
  };

  const handleResetFilters = () => {
    setFilterDate(null);
    fetchAllDevotions();
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleOpenModal = (id) => {
    if (id) {
      const devotionToEdit = devotions.find(devotion => devotion.id === id);
      if (devotionToEdit) {
        setNewDevotion({
          scriptures: devotionToEdit.scriptures,
          prayer: devotionToEdit.prayer,
          date: new Date(devotionToEdit.date),
          devotion_id: id
        });
        setIsEditing(true);
      } else {
        return;
      }
    } else {
      setNewDevotion({ scriptures: [''], prayer: '', date: new Date(), devotion_id: '' });
      setIsEditing(false);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setNewDevotion({ scriptures: [''], prayer: '', date: new Date(), devotion_id: '' });
    setShowModal(false);
    setIsEditing(false);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteDevotionId(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteDevotionId(null);
    setShowDeleteModal(false);
  };

  const columns = useMemo(() => [
    {
      Header: 'Scriptures',
      accessor: 'scriptures', // accessor is the "key" in the data
      Cell: ({ value }) => value.join(', '),
    },
    {
      Header: 'Prayer',
      accessor: 'prayer',
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: ({ value }) => new Date(value).toLocaleDateString(),
      sortType: (rowA, rowB, columnId) => {
        const dateA = new Date(rowA.values[columnId]);
        const dateB = new Date(rowB.values[columnId]);
        return dateA - dateB;
      },
    },
    {
      Header: 'Actions',
      Cell: ({ row }) => (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button onClick={() => handleOpenModal(row.original.id)} className="modal-button small-button">
            <FaEdit />
          </button>
          <button onClick={() => handleOpenDeleteModal(row.original.id)} className="modal-button small-button">
            <FaTrash />
          </button>
        </div>
      )
    },
  ], []);

  const tableInstance = useTable({ columns, data: devotions }, useFilters, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div className="dashboard-container">
      <Header handleLogout={handleLogout} />
      <div className="dashboard-content">
        <h1>Daily Devotion Manager</h1>
        <div className="table-header">
          <button type="button" onClick={() => handleOpenModal(null)} className="submit-button small-button">
            <FaPlus />
            <span>Add Devotion</span>
          </button>
          <div className="filters">
            <div className="filter-group">
              <label htmlFor="filterDate">Filter by Date:</label>
              <DatePicker
                selected={filterDate}
                onChange={handleFilterDateChange}
                dateFormat="yyyy-MM-dd"
                className="filter-date-picker"
              />
              <button onClick={handleFilterByDate} className="modal-button small-button">
                <FaFilter />
              </button>
              <button onClick={handleResetFilters} className="modal-button small-button">
                <FaUndo />
              </button>
            </div>
          </div>
        </div>
        <div className="table-container">
          {isLoading ? (
            <div className="loading">Loading...</div>
          ) : (
            <table {...getTableProps()} className="devotions-table">
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        {column.isSorted
                          ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                          : ''}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {showModal && (
        <Modal onClose={handleCloseModal}>
          <form onSubmit={handleSubmit} className="devotion-form">
            <div className="form-group">
              <label htmlFor="scriptures">Scriptures:</label>
              <input
                type="text"
                id="scriptures"
                name="scriptures"
                value={newDevotion.scriptures[0]}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="prayer">Prayer:</label>
              <textarea
                id="prayer"
                name="prayer"
                value={newDevotion.prayer}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="date">Date:</label>
              <DatePicker
                selected={newDevotion.date}
                onChange={handleDateChange}
                dateFormat="yyyy-MM-dd"
                className="date-picker"
              />
            </div>
            <button type="submit" className="modal-button small-button">
              <FaSave /> {isEditing ? 'Update' : 'Create'} Devotion
            </button>
          </form>
        </Modal>
      )}
      {showDeleteModal && (
        <Modal onClose={handleCloseDeleteModal}>
          <div className="delete-confirmation">
            <p>Are you sure you want to delete this devotion?</p>
            <button onClick={handleDelete} className="modal-button confirm-delete small-button">
              Yes, Delete
            </button>
            <button onClick={handleCloseDeleteModal} className="modal-button cancel-delete small-button">
              Cancel
            </button>
          </div>
        </Modal>
      )}
      <Footer />
    </div>
  );
};

export default Devotion;
