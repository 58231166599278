import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { fetchSubscribers, deleteSubscriber } from '../services/apiService';
import { useAuth } from '../services/AuthContext';
import { FaSignOutAlt, FaTrash, FaHome, FaUsers, FaBook } from 'react-icons/fa';
import Header from './Header'; // Import the new Header component
import Footer from './Footer'; // Import the new Footer component
import Modal from './common/Modal'; // Import your modal component
import '../styles/App.css';

const Subscribers = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSubscriberId, setDeleteSubscriberId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { getAuthToken, logout } = useAuth();

  useEffect(() => {
    fetchAllSubscribers();
  }, [getAuthToken]);

  const fetchAllSubscribers = async () => {
    try {
      setIsLoading(true);
      const token = getAuthToken();
      const response = await fetchSubscribers(token);
      console.log("Fetched data:", response);

      if (response && response.is_success && Array.isArray(response.data)) {
        setSubscribers(response.data);
      } else {
        console.error("Expected an array but got:", response);
        setSubscribers([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching subscribers:', error);
      setSubscribers([]);
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      const token = getAuthToken();
      console.log('Deleting subscriber with ID:', deleteSubscriberId);
      await deleteSubscriber(token, deleteSubscriberId);
      setShowDeleteModal(false);
      setDeleteSubscriberId(null);
      fetchAllSubscribers();
    } catch (error) {
      console.error('Error deleting subscriber:', error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteSubscriberId(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteSubscriberId(null);
    setShowDeleteModal(false);
  };

  return (
    <div className="dashboard-container">
      <Header handleLogout={handleLogout} />
      <div className="dashboard-content">
        <h1>Subscribers</h1>
        <div className="table-container">
          {isLoading ? (
            <div className="loading">Loading...</div>
          ) : (
            <table className="subscribers-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {subscribers.map((subscriber) => (
                  <tr key={subscriber.id}>
                    <td>{subscriber.name}</td>
                    <td>{subscriber.email}</td>
                    <td>
                      <button onClick={() => handleOpenDeleteModal(subscriber.id)} className="modal-button small-button"><FaTrash /></button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      <Footer />
      {showDeleteModal && (
        <Modal onClose={handleCloseDeleteModal}>
          <div className="delete-confirmation">
            <p>Are you sure you want to delete this subscriber?</p>
            <button onClick={handleDelete} className="modal-button confirm-delete small-button">
              Yes, Delete
            </button>
            <button onClick={handleCloseDeleteModal} className="modal-button cancel-delete small-button">
              Cancel
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Subscribers;
