const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export async function getToken(username, password) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({ username, password }).toString(),
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch token: ${response.statusText}`);
    }

    const data = await response.json();
    return data.access_token;
  } catch (error) {
    console.error('Error in getToken:', error.message);
    throw error;
  }
}

export async function fetchDevotions(token) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/devotion`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch devotions: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in fetchDevotions:', error.message);
    throw error;
  }
}

export async function fetchDevotion(token, devotion_id) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/devotion/${devotion_id}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch devotion: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in fetchDevotion:', error.message);
    throw error;
  }
}

export async function createDevotion(token, devotion) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/devotion`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(devotion),
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error('Error details:', errorDetails);
      throw new Error(`Failed to create devotion: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in createDevotion:', error.message);
    throw error;
  }
}

export async function updateDevotion(token, devotion) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/devotion`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        prayer: devotion.prayer,
        date: devotion.date,
        scriptures: devotion.scriptures,
        devotion_id: devotion.devotion_id
      }),
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error('Error details:', errorDetails);
      throw new Error(`Failed to update devotion: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in updateDevotion:', error.message);
    throw error;
  }
}

export async function deleteDevotion(token, devotion_id) {
  try {
    console.log('Sending DELETE request for ID:', devotion_id);
    const response = await fetch(`${API_BASE_URL}/api/v1/devotion/${devotion_id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error('Error details:', errorDetails);
      throw new Error(`Failed to delete devotion: ${response.statusText}`);
    }

    return true;
  } catch (error) {
    console.error('Error in deleteDevotion:', error.message);
    throw error;
  }
}

export async function fetchSubscribers(token) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/subscribers`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch subscribers: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error in fetchSubscribers:', error.message);
    throw error;
  }
}

export async function deleteSubscriber(token, subscription_id) {
  try {
    const response = await fetch(`${API_BASE_URL}/api/v1/subscribers/${subscription_id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorDetails = await response.json();
      console.error('Error details:', errorDetails);
      throw new Error(`Failed to delete subscriber: ${response.statusText}`);
    }

    return true;
  } catch (error) {
    console.error('Error in deleteSubscriber:', error.message);
    throw error;
  }
}
