import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';
import { useAuth } from '../services/AuthContext';
import '../styles/App.css';

const Home = () => {
  const navigate = useNavigate();
  const { getAuthToken, logout } = useAuth();

  useEffect(() => {
    const token = getAuthToken();
    if (!token) navigate('/login');
  }, [getAuthToken, navigate]);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <div className="dashboard-container">
      <Header handleLogout={handleLogout} />
      <div className="dashboard-content">
        <h1>Welcome to Momentum Peak</h1>
        <p>Manage your daily devotions and subscribers efficiently.</p>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
