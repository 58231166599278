import React from 'react';
import { Link } from 'react-router-dom';
import { FaSignOutAlt, FaHome, FaBook, FaUsers } from 'react-icons/fa';
import { useAuth } from '../services/AuthContext';
import '../styles/App.css';

const Header = ({ handleLogout }) => (
  <nav className="navbar">
    <div className="text-logo">
      <span className="logo-part1">momentum</span>
      <span className="logo-part2">Peak</span>
    </div>
    <ul className="navbar-links">
      <li><Link to="/" className="navbar-link"><FaHome /> <span>Home</span></Link></li>
      <li><Link to="/devotion" className="navbar-link"><FaBook /><span>Daily Devotion</span></Link></li>
      <li><Link to="/subscribers" className="navbar-link"><FaUsers /><span>Subscribers</span></Link></li>
      <li><button onClick={handleLogout} className="navbar-link signout-button"><FaSignOutAlt className="logout-icon" /></button></li>
    </ul>
  </nav>
);

export default Header;
