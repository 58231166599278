import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './services/AuthContext';
import ProtectedRoute from './components/common/ProtectedRoute';
import Home from './components/Home';
import Login from './components/Login';
import Devotion from './components/Devotion';
import Subscribers from './components/Subscribers';
import './styles/App.css';

const App = () => (
  <AuthProvider>
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
        <Route path="/devotion" element={<ProtectedRoute><Devotion /></ProtectedRoute>} />
        <Route path="/subscribers" element={<ProtectedRoute><Subscribers /></ProtectedRoute>} />
      </Routes>
    </Router>
  </AuthProvider>
);

export default App;
